.reviews_list {
  background: #ffffff;
  border-radius: 8px;
}
.reviews-title {
  border-radius: 8px 8px 0 0;
  border: none;
  box-shadow: 5px 5px 35px rgba(206, 206, 206, 0.3);
  min-height: 74px;
  position: relative;
  border: none !important;
  z-index: 1;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
}
.users_top_bar h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
.users_top_bar span {
  font-family: "Poppins", sans-serif;
}

.rev_right {
  display: flex;
  width: 600px;
  justify-content: flex-end;
  align-items: center;
}
.search_table_full .ant-input-affix-wrapper input {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #2f373b !important;
  font-size: 20px;
}
.search_table_full .ant-input-affix-wrapper {
  max-width: 145px;
  padding: 0px 10px;
  float: right;
  align-items: center;
  margin-right: 10px;
}
.search_table_full .ant-input-affix-wrapper-focused {
  max-width: 340px;
  min-height: 56px;
  border: 2px solid #e9e9e9 !important;
  border-radius: 8px;
}
.inv_top_ft_ed span {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  float: right;
}
.inv_top_ft_ed span svg {
  width: 24px;
  height: 24px;
  text-align: center;
  color: #2f373b !important;
}
.list_reviews {
  padding: 25px 25px;
}
.reviews_top_tt .reviews_left_md {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.media-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-in img {
  width: 42px;
  height: 42px;
  margin-right: 15px;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.media-body h5 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}
.media-body span {
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: #2f373b;
}
.reviews_star {
  display: flex;
  align-items: center;
}
.reviews_star span {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #2f373b;
  margin-right: 10px;
}
.reviews_star ul li {
  display: inline-block;
}
.reviews_star ul li a {
  display: block;
  font-size: 24px;
  line-height: 28px;
  color: #e5cf0a;
}
.reviews_dit .reviews_time {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.time_r span {
  display: block;
  font-size: 14px;
  color: #878b8d;
}
.time_r span.tm {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 3px;
  color: #2f373b;
}
.ti_line {
  background: #e9e9e9;
  width: 2px;
  height: 20px;
  margin: 0px 30px;
}
.reviews_dit .ant-typography {
  font-size: 14px;
  color: #2f373b;
}
.rew_btn button {
  padding: 5px 15px;
  line-height: 17px;
  border-radius: 8px;
  border: none;
}
.rew_btn.apv button {
  background: #e9ffe8;
  color: #3bc236;
}

.rew_btn.rejected button {
  background: #fff1f2;
  color: #ec1c31;
}

.rew_btn.pending button {
  background: #fff7e6;
  color: #d46b08;
}

.rew_btn_ft {
  display: flex;
  align-items: center;
}
.rew_btn_ft .ant-btn-link {
  padding: 0px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
}
.contentpage_pg {
  text-align: center;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  width: 100%;
}
.contentpage_pg .ant-pagination {
  display: inline-block;
  margin: 0 auto;
}
.contentpage_pg
  .ant-pagination
  li.ant-pagination-prev
  button.ant-pagination-item-link {
  border: none;
  background: none;
  border-radius: 8px;
}
.contentpage_pg .ant-pagination li {
  border-radius: 8px;
  background: #f5f7fa;
  border-width: 2px;
}
.contentpage_pg
  .ant-pagination
  li.ant-pagination-next
  button.ant-pagination-item-link {
  border: none;
  background: none;
  border-radius: 8px;
}
.contentpage_pg .ant-pagination li button.ant-pagination-item-link:hover {
  color: #2f373b;
}
.contentpage_pg .ant-pagination .ant-pagination-prev:hover,
.contentpage_pg .ant-pagination .ant-pagination-next:hover {
  background: #eaeff6;
}
.contentpage_pg .ant-pagination li.ant-pagination-item-active {
  background: rgba(235, 74, 28, 0.05);
  border: none;
}
.contentpage_pg .ant-pagination .ant-pagination-item:hover {
  background: rgba(235, 74, 28, 0.05);
  border-color: rgba(235, 74, 28, 0.05);
}
.contentpage_pg span {
  font-size: 14px;
  font-weight: normal;
}
.contentpage_pg .ant-pagination li:last-child {
  float: right;
}
.contentpage_pg
  .ant-pagination
  li.ant-pagination-options
  .ant-select
  .ant-select-selector {
  border-radius: 8px;
  background: #f5f7fa;
  border-width: 2px;
}
.export_dw {
  width: 228px;
  border-radius: 8px;
}
.export_dw li {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
}
.export_dw li:hover {
  background: none;
  color: #eb4a1c;
}
.addpage_bx_cnt {
  justify-content: center;
  max-width: 1053px;
  margin: 0 auto;
  width: 100%;
}
.addpage_bx_cnt .ant-breadcrumb span {
  font-size: 15px;
  font-weight: 400;
  color: #2f373b;
}
.addpage_bx_cnt .ant-breadcrumb span a {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.addpage_bx_cnt .ant-breadcrumb span:last-child .ant-breadcrumb-link {
  color: #eb4a1c;
}
.bra_righttext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_btn {
  background: #edf1f8;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 8px;
  border: 2px solid #e5edf9;
}
.text_btn span {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.text_btn_guest {
  background: #f5eeee;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 8px;
  border: 2px solid #f5eeee;
}
.text_btn_guest span {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.addpage_bx_conpage {
  justify-content: center;
  max-width: 1053px;
  margin: 0 auto;
  width: 100%;
}
.addpage_form {
  margin-top: 30px;
  border: none;
  border-radius: 8px;
  padding: 30px;
}
.addpage_form .ant-card-body {
  padding: 0px;
}
.detail_page_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail_page_title {
  margin-bottom: 30px;
}
.left_dt_title h5.ant-typography {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
  margin: 0px;
}
.rew_btn.awa button {
  background: #faffd1;
  color: #a9b918;
}
.rew_about_customer {
  margin-top: 50px;
}
.reviews_text_in {
  margin-top: 20px;
}
.text_in_dit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.in_dit_left h5.ant-typography {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 3px;
  font-family: "Poppins", sans-serif;
}
.in_dit_left span {
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: #878b8d;
  font-family: "Poppins", sans-serif;
}
.coupon_list_table .ant-table-title {
  padding: 9px 30px;
  border-radius: 8px 8px 0 0;
  border: none;
  box-shadow: 5px 5px 35px rgb(206 206 206 / 30%);
  min-height: 74px;
  position: relative;
  border: none !important;
  z-index: 1;
  display: flex;
}
.coupon_list_table .ant-table-title .ant-row {
  width: 100%;
}
.coupon_list_table .ant-table-title h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}
.search_table_full .ant-input-affix-wrapper input {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.coupon_list_table .ant-table-wrapper .ant-table {
  border-radius: 8px;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #2f373b !important;
  font-size: 20px;
}
.search_table_full .ant-input-affix-wrapper {
  max-width: 140px;
  padding: 0px 10px;
  float: right;
  align-items: center;
  margin-right: 30px;
}
.search_table_full .ant-input-affix-wrapper-focused {
  max-width: 340px;
  min-height: 56px;
  border: 2px solid #e9e9e9 !important;
  border-radius: 8px;
}
.inv_top_ft_ed span {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.inv_top_ft_ed span svg {
  width: 24px;
  height: 24px;
  text-align: center;
  color: #2f373b !important;
}
.media-in {
  display: flex;
  align-items: flex-start;
}
.media-body h5 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}
.media-body h5 a {
  color: #2f373b;
  font-family: "Poppins", sans-serif;
}
.media-body span {
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: #878b8d;
}
.coupon_list_table .ant-table-container {
  padding: 0px 25px;
  border: none !important;
}
.coupon_list_table .ant-table-container table thead tr th {
  padding: 20px 0px;
  background: #ffffff;
  font-size: 14px;
  font-weight: normal;
  border-width: 2px;
  border-color: #f1f1f1;
  border-right: none !important;
  font-family: "Poppins", sans-serif;
}
.coupon_list_table .ant-table-container table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #2f373b;
  padding: 19px 0px;
  border-width: 2px;
  border-color: #f1f1f1;
  border-right: none !important;
  font-family: "Poppins", sans-serif;
}
.coupon_list_table .ant-table-wrapper .ant-table {
  border-radius: 8px;
}
/* .ant-space {
  float: right; 
  padding: 0px 15px; 
} */
.ant-space .ant-space-item a {
  color: #2f373b;
  font-size: 22px;
}
.filter_customers {
  width: 520px;
  padding: 30px;
}
.ft_left span.ant-typography {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  display: block;
  color: #878b8d;
}
.ft_left ul {
  margin-bottom: 10px;
}
.ft_left ul li {
  line-height: 35px;
}
.ft_left ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #2f373b;
}
.ft_select label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 22px;
}
.ft_select .ant-select {
  width: 100%;
}
.ft_select .ant-select .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
}
.ft_select {
  margin-bottom: 20px;
}
.customers_active {
  float: right;
  margin-bottom: -25px;
  position: relative;
  z-index: 1;
  top: 11px;
}
.customers_active span {
  font-size: 14px;
  color: #2f373b;
  font-weight: 600;
  margin-right: 15px;
}
.addpage_form .ant-card-body .ant-tabs {
  width: 100%;
}
.customers_active button.ant-switch {
  width: 52px;
  height: 30px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
}
.customers_active button.ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.customers_active button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  box-shadow: none;
}
.customers_active button.ant-switch.ant-switch-checked {
  border-color: #ffd9ce;
}
.customers_active
  button.ant-switch.ant-switch-checked
  .ant-switch-handle::before {
  background-color: #eb4a1c;
}
.customers_active .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}
.addpage_form .ant-card-body .ant-tabs .ant-tabs-nav::before {
  display: none;
}
.addpage_form
  .ant-card-body
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-tab {
  font-size: 15px;
  font-weight: 700;
  color: #878b8d;
}
.addpage_form .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
}
.ant-tabs-content .rew_about_customer {
  margin-top: 30px;
}
.customers_ab .reviews_left_md {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editcust_top {
  margin-bottom: 25px;
}
.ed_customers a {
  color: #2f373b;
  font-size: 22px;
  margin-left: 15px;
}
.editcustomerdetails_md .ant-modal-content {
  border-radius: 8px;
}
.editcustomerdetails_md .ant-modal-body {
  padding: 50px;
}
.editcust_top span.ant-typography {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
  display: block;
}
.editcust_top span.ant-typography.ant-typography-secondary {
  color: #878b8d;
  font-size: 14px;
  font-weight: normal;
}
.deit_form_fl label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 22px;
}
.deit_form_fl
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input {
  min-height: 60px;
  border-radius: 8px;
  border-width: 2px !important;
  font-size: 14px;
  font-weight: 600;
  padding-left: 22px;
  padding-right: 22px;
}
.deit_form_fl .ant-select {
  width: 100%;
}
.deit_form_fl .ant-select .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px !important;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
}
.deit_form_fl .ant-picker {
  width: 100%;
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
}
.anticon-calendar svg {
  font-size: 22px;
  color: #2f373b;
}
.deit_form_fl
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input-content
  .ant-input-group
  .ant-input {
  min-height: 60px;
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  border-width: 2px;
  border-right-width: 2px !important;
}
.deit_form_fl
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-selector {
  min-height: 60px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px !important;
  border-width: 2px !important;
}
.deit_form_fl .ant-input-group-addon {
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px 0px 0px 8px !important;
  border-width: 2px !important;
  background-color: transparent;
}
.sub_news {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
.sub_news .ant-checkbox {
  float: right;
}
.pages_add_btn {
  margin-top: 35px;
}
.pages_add_btn .styled-button {
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  min-width: 148px;
  max-width: 148px;
  border-width: 2px;
  border-radius: 8px;
  margin-right: 20px;
}
.ord_list_cust .ant-list-header {
  border: none;
}
.ord_list_cust .ant-list-header span {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
}
.ord_list_cust .ant-spin-container .ant-list-item {
  padding: 20px 0px;
  border-width: 2px;
  border-color: #f1f1f1;
}
.ord_list_cust .ant-spin-container .ant-list-item .in_list {
  width: 100%;
}
.ord_list_top a {
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 3px;
  color: #eb4a1c;
  display: block;
}
.ord_list_top span {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
  line-height: 19px;
}
.ord_list_right {
  text-align: right;
}
.ord_list_right span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
.ord_left .text_in_dit {
  margin-bottom: 0px;
}
.ord_left .text_in_dit .ti_line {
  margin: 0px 27px;
}
.ord_left .text_in_dit .in_dit_left span {
  color: #878b8d;
}
.ord_right {
  text-align: right;
}
.rew_btn .ant-tag {
  padding: 5px 15px;
  line-height: 17px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
  font-weight: 600;
}
.rew_btn.odd button {
  background: #e9ffe8;
  color: #3bc236;
}
.rew_btn.oc button {
  background: #fff1f2;
  color: #ec1c31;
}
.rew_btn.shi button {
  background: #faffd1;
  color: #a9b918;
}
.notes_tab {
  padding-top: 20px;
}
.notes_tab span.ant-typography {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-bottom: 20px;
  color: #2f373b;
}
.notes_tab span {
  font-weight: normal;
  color: #878b8d;
}
.editor_bx label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 22px;
}
.editor_bx .quill {
  padding: 22px;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
  margin-bottom: 40px;
}
.editor_bx .ql-toolbar.ql-snow {
  border: none;
}
.editor_bx .ql-container.ql-snow {
  border: none;
}
.editor_bx .quill .ql-container.ql-snow {
  border-top: 2px solid#F1F1F1;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 10px;
  margin-top: 10px;
  min-height: 175px;
}
.address_box_add_new {
  padding-top: 20px;
}
.address_box_add_new span {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-bottom: 20px;
  color: #878b8d;
}
.addres_box_in {
  border: 2px solid #e9e9e9;
  padding: 25px;
  border-radius: 8px;
}
.addr_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.addr_tit span.at {
  font-size: 15px;
  font-weight: 600;
  color: #2f373b;
  margin-bottom: 3px;
  line-height: 20px;
}
.addr_tit .ant-typography.ant-typography-secondary {
  font-size: 14px !important;
  color: #878b8d !important;
  font-weight: normal !important;
  line-height: 20px;
  margin: 0px;
}
.addr_btn {
  display: flex;
}
.addr_btn a {
  color: #2f373b;
  font-size: 24px;
  margin-left: 15px;
}
.addr_btn a span {
  color: #2f373b;
  margin: 0px;
  font-size: 24px;
}
.adder_text span.ant-typography {
  font-size: 14px;
  color: #2f373b;
  line-height: 24px;
  margin: 0px;
}
.add_form_new {
  margin-top: 50px;
}
.add_form_new span.ant-typography {
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: #2f373b;
}
.addr_input {
  min-height: 60px;
}
.addr_input .ant-radio-group {
  width: 100%;
}
.addr_input .ant-form-item {
  margin-bottom: 30px;
}
.addr_input .ant-radio-group .ant-radio-wrapper {
  line-height: 20px;
  font-weight: 600;
  margin-right: 0px;
  width: 50%;
}
.addr_input .ant-radio-group .ant-radio-wrapper .ant-radio {
  float: left;
  margin: 0px;
}
.addr_input .ant-radio-group .ant-radio-wrapper span + span {
  line-height: 30px;
  margin: 0px;
  display: inline-block;
  margin-left: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #2f373b;
}

.ant-radio-checked .ant-radio-inner {
  background: #eb4a1c;
  margin: 0px;
}
.addr_input .ant-radio-group .ant-radio-wrapper .ant-radio .ant-radio-inner {
  margin: 0px;
}
.addr_input .input-label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 18px;
}
.addr_input
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input {
  min-height: 60px;
  border-radius: 8px;
  border-width: 2px !important;
  font-size: 14px;
  font-weight: 600;
  padding-left: 22px;
  padding-right: 22px;
}
.addr_input .ant-input-textarea textarea {
  min-height: 98px;
  border-radius: 8px;
  border-width: 2px !important;
  font-size: 14px;
  font-weight: 600;
  padding-left: 22px;
  padding-right: 22px;
}
.addr_input .ant-select {
  width: 100%;
}
.addr_input .ant-select span {
  margin: 0px;
}
.addr_input .ant-select span.ant-select-arrow {
  top: 43%;
}
.addr_input .ant-select .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
}
.addr_input
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-search {
  margin: 0px;
}
.addr_input .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #2f373b;
}
.addr_input .addr_input_btn {
  display: flex;
  align-items: center;
  padding-top: 14px;
}
.message_tab {
  padding-top: 20px;
}
.message_top span.ant-typography {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-bottom: 20px;
  color: #2f373b;
}
.message_top span {
  font-weight: normal;
  color: #878b8d;
}
.message_timeline {
  margin-top: 50px;
}
.message_timeline h5.ant-typography {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 30px;
}
.msg_timeline li .ant-timeline-item-tail {
  border-color: #e9e9e9;
}
.msg_timeline li .ant-timeline-item-head {
  width: 2px;
  height: 19px;
  border-radius: 0px;
  border-width: 1px;
  left: 4px;
}
.msg_timeline li .ant-timeline-item-content b {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 15px;
  display: block;
}
.msg_timeline li .ant-timeline-item-content p span {
  font-size: 14px;
  font-weight: 600;
  color: #878b8d;
  padding: 0px 10px;
}
.msg_timeline li .ant-timeline-item-content p {
  font-size: 14px;
  font-weight: normal;
  color: #2f373b;
  margin: 0px;
  margin-bottom: 5px;
}
.data_remove {
  text-align: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data_remove button {
  padding: 8px 20px;
  background: #f5f5f5;
  border-radius: 8px;
  border: none;
  line-height: 24px;
  height: auto;
  box-shadow: none;
}
.data_remove button svg {
  float: left;
}
.data_remove button > span {
  float: right;
  margin-left: 20px;
}
.data_remove button:hover {
  color: #2f373b;
}
.erase_md .ant-modal-content {
  border-radius: 8px;
}
.erase_data_md {
  text-align: center;
  padding: 20px 0px;
}
.erase_img {
  margin: 30px 0px;
}
.erase_text h3.ant-typography {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 19px;
}
.erase_text p {
  font-size: 16px;
  line-height: 24px;
  color: #2f373b;
}
.erase_brn {
  margin-top: 20px;
}
.erase_brn button {
  min-height: 58px;
  margin: 0px 10px;
  border-width: 2px;
  border-color: #e9e9e9;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 40px;
}
.erase_brn button:hover {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #2f373b;
}
.back_link {
  margin-top: 30px;
}
.back_link a {
  font-size: 16px;
  font-weight: 600;
  color: #eb4a1c;
}
.default_setcust_btn {
  background: rgba(235, 74, 28, 0.05);
  color: #eb4a1c;
  border-color: rgba(235, 74, 28, 0.05);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
}
.default_setcust_btn:hover {
  background: rgba(235, 74, 28, 0.05);
  border-color: rgba(235, 74, 28, 0.05);
}
.default_setcust_btn span {
  margin: 0px;
  padding: 0px;
  color: #eb4a1c;
}
.default_cust_btn {
  background: #eb4a1c;
  color: #ffffff;
  border-color: #eb4a1c;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
}
.default_cust_btn span {
  margin: 0px;
  padding: 0px;
  color: #ffffff;
}
.default_cust_btn:hover {
  background: #eb4a1c;
  border-color: #eb4a1c;
}
.adder_text {
  margin-bottom: 20px;
}
.addres_box_in {
  margin-bottom: 30px;
}
.noaddress {
  text-align: center;
  padding: 60px 0px;
}
.noaddress span.ant-typography {
  margin: 0px;
}
.noaddress span.ant-typography a {
  color: #eb4a1c;
}
@media only screen and (max-width: 767px) {
  .filter_customers {
    width: 100%;
  }
}

.tag-font-active {
  color: #3bc236;
  border-radius: 5px;
}
.pr_filter .input-label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 18px;
}
.pr_filter .ant-select .ant-select-selector {
  min-height: 60px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px !important;
  border-width: 2px !important;
}
.pr_filter .ant-select-multiple .ant-select-selection-placeholder {
  padding: 0px 10px;
}
.pr_filter
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-search
  .ant-select-selection-search-input {
  min-height: 56px;
}
.pr_filter .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  line-height: 58px;
}

.text-alin {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_category_form_input .text-alin .ant-form-item {
  margin-bottom: 0px;
}
.button-box {
  margin-top: 30px;
}
.text-alin span {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.text-alin button.ant-switch {
  width: 52px;
  height: 30px;
  border: 2px solid #e9e9e9;
  background-color: transparent !important;
}
.text-alin button.ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.text-alin button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  box-shadow: none;
}
.text-alin .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}
.text-alin button.ant-switch.ant-switch-checked {
  border-color: #ffd9ce;
}
.text-alin button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  background-color: rgb(233, 233, 233);
  box-shadow: none;
}
.text-alin button.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #eb4a1c;
}
.categories_bot_in button.ant-btn.ant-btn-primary {
  background-color: #fc3345;
}
.categories_bot_in button.ant-btn.ant-btn-primary:hover {
  background-color: #121212;
}
.categories_bot_in button.ant-btn.ant-btn-default:hover {
  color: #eb4a1c;
  border-color: #eb4a1c;
}

.media-in .media-body h5 a {
  color: #fc3345;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.addpage_form
  .ant-card-body
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fc3345;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.addpage_form
  .ant-card-body
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-ink-bar {
  background-color: #fc3345;
}
.in_dit_left{}
