.grid-action-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.avatar {
  margin-top: 5px;
}

.ant-btn-background-ghost.ant-btn-primary {
  border-width: 2px;
  min-height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  margin-left: 10px;
  float: right;
}
.outline-div {
  padding: 10px;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  width: 100%;
}
.inner-content-pricevalue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inventory-checkbox {
  margin: 0% 0% 5% 55%;
  /* margin-top: -40px;
  margin-left: 226px; */
}

.move-to-stock-button {
  margin: 5% 0% 0% 25%;
}

.related-product-dashed-button {
  height: 100%;
  width: 100%;
}
.related-product-card {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 0;
  box-shadow: 5px 5px 25px #cecece;
}
.related-product-card .ant-card-body h5.ant-typography {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 27px;
}
.related-product-card .ant-card-body span.ant-typography {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.back_top_pg {
  display: inline-block;
}
.back_top_pg a {
  color: #2f373b;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}
.add_user_top h3.ant-typography{
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
}
.add_user_top h3.ant-typography .ant-tag{
  font-family: "Poppins", sans-serif;
}

.add_user_top .ant-breadcrumb ol li a{
  font-size:14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.add_user_top .ant-breadcrumb ol li span{
  font-size:14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}


.back_top_pg a span.back_in {
  width: 28px;
  height: 28px;
  border: 2px solid #e9e9e9;
  display: block;
  text-align: center;
  border-radius: 8px;
  margin-right: 10px;
  line-height: 23px;
}
.stock-validation-err-msg {
  color: #ff4d76;
  margin-top: -1.5%;
  position: fixed;
}

.right_shfibtn {
  align-items: center;
}
.add_product_cnt {
  justify-content: center;
}
h3.ant-typography {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 10px;
}
.add_product_cnt .ant-breadcrumb span {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.add_product_cnt .ant-breadcrumb span:last-child .ant-breadcrumb-link {
  color: #eb4a1c;
}
.add_product_form_tab {
  border-radius: 8px;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  font-size: 15px;
  font-weight: bold;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  label.input-label {
  font-size: 12px;
  font-weight: 600;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-input {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  input[type="number"]::-webkit-inner-spin-button,
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}

.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-select
  .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}
.add_product_form_tab .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}
.product_all_form button.styled-button {
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  min-width: 148px;
  max-width: 148px;
  border-width: 2px;
  border-radius: 8px;
  margin-right: 20px;
}
.all_in_title_text span.ant-typography {
  font-size: 16px;
  font-weight: 600;
  display: block;
}
.all_in_title_text span.ant-typography.ant-typography-secondary {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
}
.add_product_form_tab .ant-tabs-nav {
  margin-bottom: 40px;
}
.product_all_form .upload-area button.styled-button {
  margin-top: 15px;
}
.add_gallery_img {
  margin-top: 15px;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn {
  border-width: 2px;
  border-color: #e9e9e9;
  min-height: 60px;
  border-radius: 8px;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn:hover {
  border-color: #eb4a1c;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn
  span.anticon-plus {
  width: 38px;
  height: 38px;
  border: 2px dashed #e9e9e9;
  line-height: 38px;
  border-radius: 8px;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  padding: 0px 13px;
  border-width: 2px !important;
  border-radius: 8px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_product_form_tab
  .tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input {
  min-height: 56px !important;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-suffix {
  width: 24px;
  height: 24px;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper:hover {
  box-shadow: none !important;
}
.ant-input-affix-wrapper {
  box-shadow: none !important;
}
.ant-input-affix-wrapper {
  box-shadow: none !important;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  margin-bottom: -15px;
  margin-top: -10px;
  margin-left: 10px;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
  color: #a7a7a7;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker {
  min-height: 60px;
  border-width: 2px !important;
  border-radius: 8px;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker
  .ant-picker-input
  input {
  font-size: 14px;
  font-weight: 600;
}
.ant-picker-focused {
  box-shadow: none;
}
.add_new_price_bx button.outlined-button {
  width: 100%;
  border-width: 2px;
  border-radius: 8px;
  min-height: 98px;
  font-size: 16px;
  font-weight: 600;
}
.add_new_price_bx button.outlined-button span.anticon-plus {
  width: 38px;
  height: 38px;
  border: 2px dashed #e9e9e9;
  line-height: 38px;
  border-radius: 8px;
}
.add_new_price_bx button.outlined-button span.anticon-plus svg {
  font-size: 18px;
  color: #eb4a1c;
}
.price_in_ft {
  min-height: 98px;
  padding: 15px 10px;
}
.price_in_ft .inner-content-pricevalue:first-child {
  font-size: 20px;
  font-weight: bold;
}
.price_in_ft .inner-content-pricevalue:first-child span {
  font-size: 13px;
  font-weight: normal;
}
.price_in_ft .inner-content-pricevalue:last-child {
  font-size: 15px;
  font-weight: 600;
}
.inventory_set_swh button.ant-switch {
  width: 52px;
  height: 30px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
}
.inventory_set_swh button.ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.inventory_set_swh button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  box-shadow: none;
}
.inventory_set_swh button.ant-switch:focus {
  box-shadow: none;
}
.inventory_set_swh .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}
.inventory_set_swh
  button.ant-switch.ant-switch-checked
  .ant-switch-handle::before {
  background-color: #eb4a1c;
}
.inventory_set_swh button.ant-switch.ant-switch-checked {
  border-color: #ffd9ce;
}
.product_all_form .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-width: 2px;
}
.inventory_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  max-width: 420px;
  justify-content: space-between;
}
.inventory_text svg {
  margin-left: 10px;
  fill: #878b8d;
}
.inventory_set_swh {
  margin-bottom: 0px;
}
.product_invs_center {
  align-items: center;
}
.prd_date_text
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker {
  min-height: 60px;
  border-width: 2px !important;
  border-radius: 8px;
}
.prd_date_text
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  margin-bottom: -15px;
  margin-top: -10px;
  margin-left: 10px;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
  color: #a7a7a7;
}
.prd_date_text .ant-form-item {
  max-width: 420px;
}
.in_text_a span.ant-typography.ant-typography-secondary {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
}
.border-manage-stock .ant-card.ant-card-bordered {
  background: #ffffff;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
}
.border-manage-stock .ant-card-body .ant-col {
  padding: 0px !important;
}
.stock_bx {
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
}
.stock_bx span {
  color: #878b8d;
}
.stock_bx span.ant-typography b {
  font-size: 15px;
  font-weight: 600;
  color: #2f373b;
}
.stock_bx .inventory-checkbox {
  margin: 0px;
}
.ma_stock {
  max-width: 420px;
}
.ma_stock .ant-card-body .ant-input {
  min-height: 60px;
  border-width: 2px !important;
}
.anticon-calendar svg {
  font-size: 22px;
  color: #2f373b;
}
.shipping_input
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  padding: 0px 13px;
  border-width: 2px !important;
  border-radius: 8px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
}
.inventory_text .ant-form-item {
  margin: 0px;
}
.shipping_input
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-prefix {
  order: 1;
  font-size: 18px;
}
.shipping_input label {
  z-index: 6;
}
.del_fees_input
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  padding: 0px 13px;
  border-width: 2px !important;
  border-radius: 8px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
}
.del_fees_input
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-prefix {
  order: 1;
  font-size: 18px;
}
.del_fees_input
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-suffix {
  width: 24px;
  height: 24px;
  font-size: 18px;
}
.del_fees_input .ant-col .input-label {
  z-index: 3;
}
.product_all_form
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  textarea.ant-input {
  min-height: 98px;
  height: 98px !important;
}
.low_stock_bx .ant-form-item {
  max-width: 420px;
}
.pr_general_se .inventory_text {
  margin-bottom: 20px;
}
.pr_general_se .inventory_text span {
  margin-right: 0px;
}
.category_tree {
  display: block;
  padding-left: 15px;
}
.category_tree span.ant-typography {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}
.category_tree div {
  line-height: 19px;
}
.category_tree span.ant-typography.ant-typography-secondary {
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.tree_category
  .ant-tree
  .ant-tree-list
  .ant-tree-list-holder-inner
  .ant-tree-treenode {
  align-items: center;
}
.tree_category
  .ant-tree
  .ant-tree-treenode
  .ant-tree-checkbox
  .ant-tree-checkbox-inner {
  border-width: 2px;
}
.tree_category .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}
.tree_category
  .ant-tree
  .ant-tree-list
  .ant-tree-list-holder-inner
  .ant-tree-treenode
  .ant-tree-checkbox {
  margin-top: 0px;
}
.tree_category
  .ant-tree
  .ant-tree-list
  .ant-tree-list-holder-inner
  .ant-tree-treenode
  .ant-tree-switcher {
  line-height: 62px;
}

.tree_category .ant-tree-icon-hide .ant-tree-treenode .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.tree_category
  .ant-tree-icon-hide
  .ant-tree-treenode
  .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 2px solid #d9d9d9;
  content: "";
}
.tree_category
  .ant-tree
  .ant-tree-list
  .ant-tree-list-holder-inner
  .ant-tree-treenode
  .ant-tree-node-content-wrapper {
  padding: 12px 0px;
}

.tree_category
  .ant-tree
  .ant-tree-list
  .ant-tree-list-holder-inner
  .ant-tree-treenode
  .ant-tree-node-content-wrapper
  .ant-avatar-lg.ant-avatar-icon {
  border-radius: 8px;
}

.products_list_main {
  background: #ffffff;
  padding: 25px;
  border-radius: 0px 0px 8px 8px;
}
.products_list_main .ant-list .ant-list-header {
  padding-top: 0px;
  border: none;
  padding-bottom: 25px;
}
.products_list_main
  .ant-list
  .ant-spin-nested-loading
  .ant-list-items
  .ant-row {
  align-items: center;
}
.products_list_main
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  tr
  th {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: rgba(253, 104, 62, 0.1);
}

.products_list_main
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  tbody
  tr
  td
  .ant-space
  .ant-space-item
  a
  span {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.products_list_main
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  tbody
  tr
  td
  span {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.products_list_main
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  tbody
  tr
  td
  span.ant-avatar {
  width: 55px;
  height: 55px;
}
.pr_list_in_text .ant-avatar {
  border-radius: 8px;
  width: 42px;
  height: 42px;
}
.pr_list_in_text .pr_title_list {
  margin-left: 15px;
  display: block;
  font-weight: normal;
}
.pr_list_in_text .pr_title_list b {
  font-size: 15px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.pr_list_in_text span {
  font-size: 15px;
  font-weight: 600;
}
.st_color {
  background: #e9ffe8;
  padding: 4px 12px;
  border-radius: 8px;
}
.filter_pupup .ant-modal-content {
  border-radius: 8px;
}
.filter_popup_form
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-selector {
  min-height: 60px;
  border-width: 2px !important;
}
.filter_popup_form
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-arrow {
  font-size: 16px;
  color: #2f373b;
  top: 50%;
}
.filter_popup_form .ant-select-selector .ant-select-selection-item {
  border-radius: 8px;
  background: #f2f2f2;
  height: 40px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 600;
}
.filter_popup_form
  .ant-select-selector
  .ant-select-selection-item
  .ant-select-selection-item-content {
  margin-right: 10px;
}
.filter_popup_form
  .ant-select-selector
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  font-size: 15px;
  color: #2f373b;
}
.filter_popup_form .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-width: 2px;
}
.filter_popup_form .styled-button {
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  min-width: 148px;
  max-width: 148px;
  border-width: 2px;
  border-radius: 8px;
  margin-right: 20px;
}
.seo_banner {
  background: #ffffff;
  border-radius: 8px;
  padding: 50px;
  box-shadow: 5px 5px 25px #cecece;
  margin: 0px 30px;
}
.seo_banner {
  background: url("./../../../Assets/product_seo.png") no-repeat bottom right;
  position: relative;
  margin-bottom: 50px;
}
.seo_url_box {
  max-width: 360px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 5px 5px 25px #cecece;
  border-radius: 8px;
  margin-bottom: 65%;
}
.seo_url_box span {
  display: block;
  color: #2f373b;
  margin-bottom: 3px;
}
.seo_url_box a {
  font-size: 15px;
  font-weight: 600;
  color: #1a0dab;
  display: block;
  margin-bottom: 8px;
}
.seo_url_box p {
  font-size: 14px;
  color: #878b8d;
}
.rankin_box {
  margin-top: 50px;
  max-width: 310px;
  display: none;
}
.rankin_box h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rankin_box p {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #878b8d;
}
.rankin_box button.ant-btn-primary {
  min-height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 30px;
}
.add_product_form_tab .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}
.product_all_form button.styled-button {
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  min-width: 148px;
  max-width: 148px;
  border-width: 2px;
  border-radius: 8px;
  margin-right: 20px;
}
.all_in_title_text span.ant-typography {
  font-size: 16px;
  font-weight: 600;
  display: block;
}
.all_in_title_text span.ant-typography.ant-typography-secondary {
  font-size: 14px;
  font-weight: normal;
  color: #878b8d;
}
.add_product_form_tab .ant-tabs-nav {
  margin-bottom: 40px;
}
.product_all_form .upload-area button.styled-button {
  margin-top: 15px;
}
.add_gallery_img {
  margin-top: 15px;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn {
  border-width: 2px;
  border-color: #e9e9e9;
  min-height: 60px;
  border-radius: 8px;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn:hover {
  border-color: #eb4a1c;
}
.add_gallery_img
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-btn
  span.anticon-plus {
  width: 38px;
  height: 38px;
  border: 2px dashed #e9e9e9;
  line-height: 38px;
  border-radius: 8px;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  padding: 0px 13px;
  border-width: 2px !important;
  border-radius: 8px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_product_form_tab
  .tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input {
  min-height: 56px !important;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-suffix {
  width: 24px;
  height: 24px;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper:hover {
  box-shadow: none !important;
}
.ant-input-affix-wrapper {
  box-shadow: none !important;
}
.ant-input-affix-wrapper {
  box-shadow: none !important;
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-prefix {
  line-height: 16px;
  font-weight: 600;
  margin-right: 8px;
  /* order: 1; */
}
.tax_input_price
  .ant-form-item
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input-prefix
  div {
  font-size: 16px;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  label {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  margin-bottom: -15px;
  margin-top: -10px;
  margin-left: 10px;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
  color: #a7a7a7;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker {
  min-height: 60px;
  border-width: 2px !important;
  border-radius: 8px;
}
.price_date
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker
  .ant-picker-input
  input {
  font-size: 14px;
  font-weight: 600;
}
.ant-picker-focused {
  box-shadow: none;
}
.add_new_price_bx button.outlined-button {
  width: 100%;
  border-width: 2px;
  border-radius: 8px;
  min-height: 98px;
}
.add_new_price_bx button.outlined-button span.anticon-plus {
  width: 38px;
  height: 38px;
  border: 2px dashed #e9e9e9;
  line-height: 38px;
  border-radius: 8px;
}
.price_in_ft {
  min-height: 98px;
  padding: 15px 10px;
}
.price_in_ft .inner-content-pricevalue:first-child {
  font-size: 20px;
  font-weight: bold;
}
.price_in_ft .inner-content-pricevalue:last-child {
  font-size: 15px;
  font-weight: 600;
}
.inventory_set_swh button.ant-switch {
  width: 52px;
  height: 30px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
}
.inventory_set_swh button.ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.inventory_set_swh button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  box-shadow: none;
}
.inventory_set_swh button.ant-switch:focus {
  box-shadow: none;
}
.inventory_set_swh .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}

.inventory_set_swh
  button.ant-switch.ant-switch-checked
  .ant-switch-handle::before {
  background-color: #eb4a1c;
}
.inventory_set_swh button.ant-switch.ant-switch-checked {
  border-color: #ffd9ce;
}
.product_all_form .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-width: 2px;
}
.inventory_text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.inventory_text svg {
  margin-left: 10px;
  fill: #878b8d;
}
.inventory_set_swh {
  margin-bottom: 0px;
}
.product_invs_center {
  align-items: center;
}
.right_seo {
  max-width: 688px;
  text-align: center;
  margin-top: 35px;
  background: url("../../../Assets/seo_arrow.png") no-repeat left;
  background-position-x: 10px;
  background-position-y: 15px;
}
.right_seo span {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.right_seo a {
  font-weight: bold;
  color: #eb4a1c;
}
.pro_setting_box {
  border-width: 2px;
  border-radius: 8px;
}
.pro_setting_box span.anticon-plus {
  display: inline-block;
  width: 38px;
  height: 38px;
  border: 2px dashed #ffd5c9;
  line-height: 38px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.pro_setting_box span.anticon-plus svg {
  color: #eb4a1c;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.pro_setting_box span {
  display: block;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.pro_setting_box:hover {
  border-color: #878b8d;
}
.pro_setting_box:hover span.anticon-plus svg {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.related-product-card span.ant-typography.ant-typography-secondary {
  font-size: 14px;
  color: #878b8d;
  font-weight: normal;
}
.product_title_top {
  padding: 9px 30px;
  border-radius: 8px 8px 0 0;
  border: none;
  box-shadow: 5px 5px 35px rgb(206 206 206 / 30%);
  min-height: 74px;
  position: relative;
  border: none !important;
  z-index: 1;
  display: flex;
  background: #ffffff;
}
.product_title_top .full_pr_top h3 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
}
.product_title_top .full_pr_top {
  width: 100%;
}
.search_table_full .ant-form-item {
  margin-bottom: 0px;
}
.search_table_full .ant-input-affix-wrapper input {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #2f373b !important;
  font-size: 20px;
}
.search_table_full .ant-input-affix-wrapper {
  max-width: 220px;
  padding: 0px 10px;
  float: right;
  align-items: center;
  margin-right: 30px;
}
.search_table_full .ant-input-affix-wrapper-focused {
  max-width: 340px;
  min-height: 56px;
  border: 2px solid #e9e9e9 !important;
  border-radius: 8px;
}
.inv_top_ft_ed span {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.inv_top_ft_ed span svg {
  width: 24px;
  height: 24px;
  text-align: center;
  color: #2f373b !important;
}
.export_top {
  float: right;
}
.export_top .ant-dropdown-trigger {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.export_top .ant-dropdown-trigger svg {
  width: 24px;
  height: 24px;
  text-align: center;
  margin-right: 15px;
}
.export_dw {
  width: 158px;
  border-radius: 8px;
}
.export_dw li {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
}
.export_dw li:hover {
  background: none;
  color: #eb4a1c;
}

.offer_details_list .tax_input_price .ant-form-item {
  margin-bottom: 0px;
}
.offer_details_list .price_date {
  margin-bottom: 0px;
}
.offer_details_list
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  button.ant-btn-dashed {
  border-width: 2px;
  border-radius: 8px;
  font-size: 16px;
  min-height: 60px;
}
.offer_details_list
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  button.ant-btn-dashed
  span.anticon-plus {
  width: 38px;
  height: 38px;
  border: 2px dashed #e9e9e9;
  line-height: 38px;
  border-radius: 8px;
}

.offer_details_list
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .dynamic-delete-button {
  color: #ffffff;
  background: #eb4a1c;
  border-color: #eb4a1c;
  border-radius: 8px;
}
.variant_in_box span {
  font-size: 14px;
  font-weight: 600;
}
.variant_ab button {
  background: #eb4a1c;
  border-color: #eb4a1c;
  border-width: 2px;
  height: auto;
  font-size: 16px;
  padding: 6px 20px;
  border-radius: 8px;
  color: #ffffff;
}
.variant_btn span {
  font-size: 20px;
  padding: 0px 10px;
}
.pr_img_vt .ant-avatar-lg {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.m_product_title {
  margin-left: 15px;
}
.m_product_title h3 {
  font-size: 28px;
  margin-bottom: 4px;
}
.m_product_title .ant-breadcrumb span {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.m_product_title .ant-breadcrumb span a {
  color: #2f373b;
}
.m_product_title .ant-breadcrumb span:last-child .ant-breadcrumb-link {
  color: #eb4a1c;
}
.addpage_bx_cnt {
  justify-content: center;
}

.variant_btn {
  display: flex;
}
.variant_btn a {
  display: block;
  color: #000000;
}
.variant_table {
  margin-top: 15px;
}
.variant_table .variant_btn {
  justify-content: flex-end;
}
.product_title {
  display: flex;
}
.product_title span.ant-avatar {
  width: 55px;
  height: 55px;
  margin-right: 15px;
  border-radius: 8px;
}
.variant_table .ant-table-thead tr th {
  background: none;
  font-size: 14px;
  font-weight: normal;
  border-width: 2px;
  border-color: #f1f1f1;
  padding: 15px 5px;
}
.variant_table .ant-table-container table tbody tr td {
  padding: 8px 5px;
  font-size: 14px;
  font-weight: 600;
}
.variant_table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none;
}
.variant_table .ant-spin-container .ant-pagination {
  margin-bottom: 0px;
}
.variant_in_box .ant-btn-dashed {
  border-width: 2px;
  border-radius: 8px;
}
.variant_in_box .ant-btn-dashed span.anticon-plus {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 2px dashed #ffd5c9;
  color: #eb4a1c;
  border-radius: 6px;
}
.variant_in_box span.great_txt {
  font-weight: normal;
  color: #878b8d;
}
.variant_modal .ant-modal-content {
  border-radius: 8px;
}
.variant_modal .ant-modal-content .ant-modal-body .ant-form-horizontal {
  margin-top: 15px;
}
.variant_modal .ant-modal-content .ant-modal-body .ant-form-horizontal span {
  font-size: 16px;
  color: #2f373b;
  line-height: 21px;
  font-weight: 600;
}
.variant_select
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-selector {
  min-height: 60px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px !important;
  border-width: 2px;
}
.variant_select
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 14px;
}

.variant_btn button.styled-button {
  padding: 14px 0px;
  font-size: 16px;
  font-weight: bold;
  text-shadow: none;
  box-shadow: none;
  border-radius: 8px;
  height: auto;
  border-width: 2px;
  margin-right: 20px;
  padding: 12px 20px;
}
.variant_modal .variant_btn button.styled-button.ant-btn-primary span {
  color: #ffffff;
}
.available_btn {
  background: #e9ffe8;
  padding: 5px 10px;
  border-radius: 8px;
  color: #3bc236;
  display: inline-block;
}
.close_pr {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #eb4a1c;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 23px;
  line-height: 22px;
  text-align: center;
  border-radius: 0px 8px 0px 0px;
  cursor: pointer;
}
.product_filter {
  padding: 30px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: -30px;
  transition: 0.5s;
  z-index: 99;
}
.pr_filter .input-label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 18px;
}
.pr_filter .ant-select .ant-select-selector {
  min-height: 60px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px !important;
  border-width: 2px !important;
}
.pr_filter .ant-select-multiple .ant-select-selection-placeholder {
  padding: 0px 10px;
}
.check_fil_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.check_fil_list .ant-checkbox-group .ant-checkbox-group-item {
  margin-right: 35px;
  color: #2f373b;
}
.check_fil_list
  .ant-checkbox-group
  .ant-checkbox-group-item
  .ant-checkbox
  + span {
  padding-left: 15px;
}
.check_fil_list .clear_btn {
  font-size: 14px;
  font-weight: 600;
  color: #878b8d;
}
.check_fil_list .clear_btn:hover {
  color: #eb4a1c;
}
.variant_in_box .ant-avatar-lg {
  border-radius: 8px;
}
.check_fil_list .ant-row.ant-form-item {
  margin-bottom: 0px;
}
.check_fil_list
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-checkbox-wrapper {
  align-items: center;
}
.check_fil_list
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-checkbox-wrapper
  .ant-checkbox {
  top: 0px;
}

/* responsive code*/
@media (min-width: 768px) and (max-width: 991px) {
  .add_product_cnt {
    justify-content: left;
  }
  h3.ant-typography {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .add_product_cnt .ant-breadcrumb span {
    font-size: 14px;
  }
  .add_product_cnt .ant-col {
    width: 100%;
  }
  .add_product_form_tab.form-background {
    padding: 20px;
  }
  .add_product_form_tab
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab {
    margin: 0 18px 0 0;
  }
  .add_product_form_tab
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab
    .ant-tabs-tab-btn {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .add_product_cnt {
    justify-content: left;
  }
  h3.ant-typography {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .add_product_cnt .ant-breadcrumb span {
    font-size: 14px;
  }
  .add_product_cnt .ant-col {
    width: 100%;
  }
  .add_product_form_tab.form-background {
    padding: 20px;
  }
  .add_product_form_tab
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab {
    margin: 0 18px 0 0;
  }
  .add_product_form_tab
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab
    .ant-tabs-tab-btn {
    font-size: 14px;
  }
}

/* Text editor on settings */
.text_editor .quill {
  padding: 22px;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
  margin-bottom: 40px;
}

.text_editor .quill .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 2px solid#F1F1F1;
}

.text_editor .quill .ql-container.ql-snow {
  border-top: 2px solid#F1F1F1;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 10px;
  margin-top: 10px;
  min-height: 175px;
}

.text_editor #description.quill .ql-container.ql-snow {
  border-top: 0px;
}

.ant-list-pagination {
  text-align: center;
}
.ant-list-pagination ul li {
  border-width: 2px;
  border-radius: 8px;
}
.ant-list-pagination ul li button {
  border-width: 2px !important;
  border-radius: 8px !important;
}
.ant-list-pagination ul li .ant-select .ant-select-selector {
  border-width: 2px !important;
  border-radius: 8px !important;
}

.product_price_details .ant-modal-content {
  border-radius: 8px;
}
.product_price_details .ant-modal-content .ant-modal-body {
  padding: 30px;
}
.price_details_input
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}
.price_details_input
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}

.price_details_input
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input {
  min-height: 30px;
}
.price_details_btn button.ant-btn-primary,
.price_details_btn button.ant-btn-default {
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  min-width: 148px;
  max-width: 148px;
  border-width: 2px;
  border-radius: 8px;
  margin-right: 20px;
}
.del_fees_input
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  lable {
  z-index: 8;
}
.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  textarea.ant-input.input-field {
  min-height: 90px !important;
}

.add_product_form_tab
  .ant-tabs
  .ant-tabs-content-holder
  .ant-form-horizontal
  .ant-form-item.friendlyurl_input
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  textarea.ant-input.input-field {
  min-height: 60px !important;
  height: auto !important;
}

.profit-margin {
  display: flex;
  width: 100%;
  margin-top: 5px;
}
.profit-margin .item-profit {
  width: 100%;
}
.profit_text {
  display: flex;
}
.profit_text h4 {
  margin: 0px !important;
  font-size: 16px;
}
.profit_text h4 + h4 {
  margin-left: 5px !important;
}
.item-profit span {
  font-size: 12px;
  font-weight: 600;
  color: #a7a7a7;
}
.up_error {
  margin-top: -25px;
  position: relative;
  display: block;
}
.great_txt {
  padding-top: 20px;
  display: block;
}
.product_title_fx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.show_product_link button {
  background: #ffffff;
  border-radius: 8px;
  min-height: 48px;
  text-align: center;
  padding: 10px 20px;
  display: inline-flex;
  float: right;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
  color: #eb4a1c;
}
.show_product_link button svg {
  float: left;
  margin-right: 10px;
  font-size: 24px;
  margin-top: 1px;
}
.show_product_link button:hover {
  background: #eb4a1c;
  color: #ffffff;
}
.show_product_link button:focus {
  background: #ffffff;
}
.textsm {
  font-size: 15px;
  font-weight: 400;
  color: #2f373b;
}
.inv_top_ft_ed span {
  justify-content: center;
}
.edit_text {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.edit_text .anticon-edit {
  font-size: 24px;
  margin-right: 15px;
}
.pro_edit .ant-modal-content {
  border-radius: 8px;
}
.pro_edit .ant-modal-content .ant-modal-close .ant-modal-close-x {
  width: 24px;
  height: 24px;
  line-height: 22px;
  margin: 15px;
}
.edit_product_blk {
  padding: 15px;
}
.edit_product_blk h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}
.edit_product_blk span.ant-typography {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 15px;
  color: #878b8d;
  display: block;
}
.edit_form .ant-form .input-label {
  font-size: 12px;
  font-weight: 600;
  margin-left: 15px;
}
.edit_form .ant-form .ant-form-item {
  margin-bottom: 18px;
}
.edit_form
  .ant-form
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input {
  border-width: 2px !important;
  min-height: 56px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}
.edit_form
  .ant-form
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .input-field {
  border-width: 2px !important;
  border-radius: 8px;
  padding: 0px 18px;
}
.edit_form
  .ant-form
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .input-field
  .ant-input-prefix
  div {
  line-height: 16px;
  font-weight: 600;
  margin-right: 8px;
}
.pro_sale_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.pro_sale_box .sale_text {
  font-size: 14px;
  font-weight: 600;
  color: #2f373b;
}
.pro_shw_h .ant-form-item {
  margin: 0px;
  margin-left: 20px;
}
.pro_shw_h button.ant-switch {
  width: 52px;
  height: 30px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
}
.pro_shw_h button.ant-switch.ant-switch-checked {
  border-color: #ffd9ce;
}
.pro_shw_h .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}
.pro_shw_h button.ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.pro_shw_h button.ant-switch .ant-switch-handle::before {
  border-radius: 50%;
  background: #e9e9e9;
  box-shadow: none;
}
.pro_shw_h button.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #eb4a1c;
}
.prosel_show .ant-select {
  width: 100%;
}
.prosel_show .ant-select .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
}
.prosel_show .ant-picker {
  min-height: 60px;
  border-width: 2px !important;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 30px;
}
.prosel_show .ant-picker .ant-picker-input {
  padding-left: 4px;
  padding-right: 11px;
}
.prosel_show .ant-picker .ant-picker-input input {
  font-size: 15px;
  font-weight: 600;
}
.quantity_ava_shw {
  width: 100%;
  margin-bottom: 40px;
}
.add_apbtn_btn button {
  max-width: 148px;
  min-width: 148px;
  padding: 14px 0px;
  font-size: 16px;
  font-weight: bold;
  text-shadow: none;
  box-shadow: none;
  border-radius: 8px;
  height: auto;
  border-width: 2px;
  margin-right: 20px;
}
.variant_check {
  display: flex;
  align-items: center;
}
.variant_check span {
  margin-right: 5px;
}
.edit_btn_var {
  display: flex;
  align-items: center;
}
.edit_btn_var .edit_text {
  margin-right: 10px;
}
.edit_btn_var .edit_text .anticon-edit {
  margin-right: 5px;
}
.edit_form
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-selector {
  border-width: 2px !important;
  min-height: 60px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
}
.edit_form .profit-margin {
  width: 50%;
}
.edit_form .pro_sale_box .pro_shw_h .ant-form-item {
  margin-bottom: 0px;
}
.detail-card h1 {
  font-size: 26px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}
.detail-card ul li {
  border-bottom: 1px solid #cccccc;
}
.left-tit span {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.right-name h5 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #fc3345;
}
.card-list {
  padding: 10px 15px;
}
.addpage_bx_cnt .back_top_pg a span {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.addpage_bx_cnt h3{
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.bra_righttext nav ol li{
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
.bra_righttext nav ol li span{
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}