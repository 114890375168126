.card {
  width: 100%;
  border-radius: 8px;
  /* box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6); */
}
.ant-card-meta-detail > div:not(:last-child) {
  margin: 0px;
}

.userInfo {
  padding-left: 10px;
}

.tags_btn p span.ant-tag {
  font-size: 13px;
  font-weight: 600;
  /* background: #d5e9ff; */
  padding: 4px 14px;
  border: none;
  border-radius: 8px;
}
.tags_btn p:last-child {
  margin-bottom: 0px;
}
