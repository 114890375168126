/* kuber logo */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body,
html {
  width: 100%;
  height: 100%;
}
.logo {
  height: 38px;
}
.kuber-logo-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  margin-bottom: 10px;
}
/* Common System Loader */
.loader {
  position: absolute;
  left: 48%;
  top: 50%;
  z-index: 1000;
}
/* left sider navigation */
.fixedSider {
  background: #ffff;
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;

  flex: 1;
  flex-basis: 0.000000001px;
  overflow-y: auto;
  scrollbar-color: #cccccc;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* mobile responsive */
.responsiveSider {
  background: #ffff;
  height: 100vh;
}

/* main content  */
.content {
  padding: 30px;
  overflow: initial;
  background: #f5f7fa;
  min-height: 100vh;
}
.mobileContent {
  overflow: initial;
  height: 100vh;
  padding: 10px;
  background: #f5f7fa;
}

.container {
  padding-top: 24px;
  padding-bottom: 24px;
}

/* header css */

.desktop-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #f5f7fa;
  padding-left: 22px;
  margin-bottom: 10px;
}
.ant-layout-sider-zero-width-trigger {
  z-index: 99;
}
.mobile-header {
  width: 100%;
  background: #f5f7fa;
  padding-left: 22px;
  margin-bottom: 10px;
}
.ant-menu.kuber_leftbar_menu {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 120px;
  padding-left: 12px;
  padding-right: 12px;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item-selected {
  background-color: transparent;
  border-radius: 0px;
}
.ant-menu.kuber_leftbar_menu.ant-menu-inline .ant-menu-item::after {
  left: 0;
  right: auto;
  border-left: 0px solid #eb4a1c;
  border-right: none;
}
.ant-menu.kuber_leftbar_menu li {
  min-height: 38px;
  margin: 0px;
  padding: 0px;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title {
  padding-bottom: 7px;
  padding-left: 8.75px !important;
  padding-right: 30.625px;
  padding-top: 7px;
  height: auto;
  margin: 0px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title:hover {
  background-color: rgba(253, 104, 62, 0.1);
}
.ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title:hover .sub_icon .icon {
  margin-right: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.ant-menu.kuber_leftbar_menu
  li
  .ant-menu-submenu-title:hover
  .sub_icon
  .icon
  svg
  .b {
  stroke: #ffffff;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title:hover .icon {
  background-color: #fc3345;
  color: #fff;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-submenu-title:hover span {
  color: #fc3345;
}

.ant-menu.kuber_leftbar_menu
  li
  .ant-menu-submenu-title:hover
  .sub_icon
  .icon
  svg
  b {
  stroke: #ffffff;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-title-content a {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item-selected {
  padding-bottom: 7px;
  padding-left: 8.75px !important;
  padding-right: 30.625px;
  padding-top: 7px;
  height: auto;
  background-color: rgba(253, 104, 62, 0.1);
  border-radius: 40px;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-sub .ant-menu-item {
  height: auto;
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  padding-left: 70px !important;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-sub .ant-menu-item:hover {
  background-color: rgba(253, 104, 62, 0.1);
  color: #fc3345;
}
.ant-menu.kuber_leftbar_menu
  li
  .ant-menu-sub
  .ant-menu-item.ant-menu-item-selected {
  color: #fc3345;
}
.icon {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.ant-layout-sider.ant-layout-sider-light {
  transition: all 0.2s ease;
  box-shadow: 0rem 1.25rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item-selected .sub_icon a {
  color: #fc3345;
}
.top_bar_main {
  background: #ffffff;
  padding-top: 0rem;
  transition: all 0.2s ease;
  border-bottom: 0.0625rem solid #eeeeee;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 30px;
  height: 72px;
  position: relative;
  z-index: 10;
}
.search-bar .ant-input-search .ant-input-group .ant-input {
  background: none;
  border: none;
  padding-left: 35px;
  font-size: 16px;
  color: #2f373b;
  padding: 10px 18px 10px 40px;
}
.ant-input:focus {
  box-shadow: none;
}
.search-bar .ant-input-search .ant-input-group .ant-input-group-addon {
  position: absolute;
  left: 0px;
  top: 6px;
}
.search-bar .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn {
  background: none;
  border: none;
  box-shadow: none;
}
.top_right_bar ul {
  display: flex;
  align-items: center;
}
.top_right_bar ul li {
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
}
.top_right_bar ul li span {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.lg_list {
  padding: 0px;
}
.lg_list a {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.user_profile {
  max-width: 266px;
  width: 100%;
  position: fixed;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  bottom: 15px;
  left: 10px;
  border-radius: 8px;
}
.user_profile .user_inner {
  display: flex;
  justify-content: space-between;
}
.user_inner .user_img {
  margin-right: 5px;
}
.user_inner .user_dsc {
  width: 65%;
}
.user_in_img {
  max-width: 40px;
  border-radius: 8px;
}
.user_dsc span {
  font-size: 15px;
  font-weight: 500;
  color: #2f373b;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  display: block;
  width: 100px;
  font-family: "Poppins", sans-serif;
}
.user_dsc p {
  margin: 0px;
  font-size: 14px;
  color: #878b8d;
  line-height: 19px;
  font-family: "Poppins", sans-serif;
}
.user_icon span svg {
  width: 22px;
  height: 28px;
}
.user_link {
  height: 0px;
  opacity: 0;
  transition: 0.15s ease-out;
}
.user_link a {
  display: block;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #2f373b;
  padding-top: 10px;
  font-family: "Poppins", sans-serif;
}
.user_link a:last-child {
  padding-top: 0px;
}
.user_hv {
  overflow: hidden;
  transition: width 2s;
}
.user_hv:hover .user_link {
  height: 100%;
  opacity: 1;
  transition: 0.15s ease-out;
}

.ant-select.bar_icon_text .ant-select-selector {
  padding: 0px;
}
.ant-select.bar_icon_text span.ant-select-arrow {
  margin-left: 15px;
  right: 4px;
}
.bar_icon_text {
  display: flex;
  align-items: center;
}
.bar_icon_text span {
  margin-left: 8px;
}
.lg_list {
  width: 120px;
  padding: 0px;
}
.lg_list a {
  display: block;
  font-size: 14px;
  color: #2f373b;
  font-weight: 500;
}
.lg_list a:hover {
  color: #eb4a1c;
}
.set_bx span {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.set_bx span span {
  margin-left: 8px;
}
.bar_icon_text .ant-badge .ant-badge-count {
  border-radius: 6px;
  width: 26px;
  top: -5px;
  background: #fc3345;
}
.bar_icon_text .ant-badge .ant-badge-count .ant-scroll-number-only {
  margin-right: 6px;
  position: absolute;
  left: 0;
  right: 0;
}
.all_pd {
  flex: auto;
  min-height: 0;
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.main_layout {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
}
.db_icon {
  fill: none;
}
.b {
  fill: none;
}
.b {
  stroke: #2f373b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}
.ant-menu.kuber_leftbar_menu li svg.couponicon {
  margin-right: 23px;
}
.ant-menu.kuber_leftbar_menu li svg.reporticon {
  margin-right: 23px;
}
.ant-menu.kuber_leftbar_menu > li.ant-menu-item-selected svg .b {
  stroke: #ffffff;
}

.ant-menu.kuber_leftbar_menu li.ant-menu-item-selected .sub_icon .icon {
  background-color: #fc3345;
  color: #fff;
  margin-right: 10px;
}
/* .ant-menu.kuber_leftbar_menu li.ant-menu-item-selected .sub_icon .icon svg .b {
  fill: #ffffff;
  color: #fff;
} */
.sub_icon {
  display: flex;
  align-items: center;
}
.store-btn a .ant-btn {
  border-radius: 9px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  background: rgb(235, 74, 28);
  color: #fff;
  border-color: rgb(235, 74, 28);
}
.store-btn a .ant-btn svg {
  margin-right: 5px;
}
.store-btn a .ant-btn span {
  line-height: 20px;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item {
  padding-bottom: 7px;
  padding-left: 8.75px !important;
  padding-right: 30.625px;
  padding-top: 7px;
  height: auto;
  margin: 0px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 400;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item.ant-menu-item-only-child:hover {
  background-color: rgba(253, 104, 62, 0.1);
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item.ant-menu-item-only-child:hover .sub_icon .icon{
  background-color: #fc3345;
  color: #fff;
  margin-right: 10px;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item.ant-menu-item-only-child:hover .sub_icon .icon svg .b{
  stroke: #ffffff;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-item.ant-menu-item-only-child:hover .sub_icon a{
  color: #fc3345;
}
.ant-menu.kuber_leftbar_menu
  li.ant-menu-submenu.ant-menu-submenu-selected
  .ant-menu-submenu-title {
  background-color: rgba(253, 104, 62, 0.1);
  color: #fc3345;
}
.ant-menu.kuber_leftbar_menu
  li.ant-menu-submenu.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .icon {
  background-color: #fc3345;
  color: #fff;
  margin-right: 10px;
  transition: all 0.5s;
}
.ant-menu.kuber_leftbar_menu
  li.ant-menu-submenu.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .icon
  svg
  .b {
  stroke: #ffffff;
}
.ant-menu.kuber_leftbar_menu li.ant-menu-submenu .ant-menu-sub {
  background-color: transparent;
}
.ant-menu.kuber_leftbar_menu li .ant-menu-sub .ant-menu-item::before {
  position: absolute;
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  border: 0.125rem solid #cccccc;
  top: 50%;
  left: 2.2rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translateY(-50%) rotate(45deg);
}
.page-heading h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.title_text h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.title_text span {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

@media (min-width: 768px) and (max-width: 991px) {
  .user_profile {
    position: absolute;
  }
  .bar_icon_text span + span {
    display: none;
  }
  .set_bx span span {
    display: none;
  }
  .product_list_bx {
    display: block;
  }
  .product_list_bx .left_product_r,
  .product_list_bx .right_product_r {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .top_right_bar {
    margin-top: 15px;
  }
  .bar_icon_text span + span {
    display: none;
  }
  .set_bx span span {
    display: none;
  }
  .mobileContent {
    margin-top: 30px;
  }
  .recent_orders_table {
    width: 100%;
    padding: 10px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: solid 1px #e8e8e8;
    border-radius: 6px;
  }
  .box_products_card .product_list_bx {
    margin-bottom: 8px;
  }
}
