.users_top_bar .ant-btn-background-ghost {
  width: auto;
  float: right;
  padding: 10px 30px;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #eb4a1c;
  background: transparent;
  border-color: #eb4a1c;
  text-shadow: none;
}
.ant-btn-background-ghost {
  border-width: 2px;
  min-height: 56px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
}
.userlisttop_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter_right {
  margin-right: 40px;
}
.filter_right span {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.filter_right span svg {
  width: 24px;
  height: 24px;
  text-align: center;
  color: #2f373b !important;
  margin-right: 10px;
}
.export_dw {
  width: 228px;
  border-radius: 8px;
  padding-bottom: 15px;
}
.export_dw span.fs_text {
  padding: 10px 22px;
  display: block;
}
.export_dw li {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
}
.export_dw li a:hover {
  background: none;
  color: #eb4a1c;
}
.export_dw span {
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #878b8d;
  padding: 12px 24px;
  display: block;
}
.user_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search_table_full .ant-input-affix-wrapper {
  max-width: 175px;
  padding: 0px 10px;
  float: right;
  align-items: center;
  margin-right: 30px;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
}
.inventory_list_table span {
  font-size: 14px;
  font-weight: normal;
}
.search_table_full .ant-input-affix-wrapper .ant-input-prefix svg {
  color: #2f373b !important;
  font-size: 20px;
}
.search_table_full .ant-input-affix-wrapper input {
  font-size: 16px;
  font-weight: 600;
  color: #2f373b;
}
.search_table_full .ant-input-affix-wrapper-focused {
  background: #ffffff;
  max-width: 340px;
  min-height: 56px;
  border: 2px solid #e9e9e9 !important;
  border-radius: 8px;
}
