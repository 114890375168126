.login-background-image h1.ant-typography {
  font-weight: 700;
}
.textOverlay {
  letter-spacing: 0px;
  word-spacing: 2px;
  display: flex;
}
.login-background-image span.ant-typography {
  font-size: 15px;
  font-weight: 400;
}
.container {
  justify-content: center;
}
.hp-position-relative {
  position: relative;
}
.hp-bg-color-1 {
  background-color: #f6eeea;
}
.kuber-logo-login {
  /* background-image: url("../../Assets/whiteLogo.svg"); */
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 6%;
}

.right_login_form h3.ant-typography {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2f373b;
  font-family: "Poppins", sans-serif;
}
.right_login_form span.ant-typography {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.right_login_form .ant-form label {
  font-size: 14px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .input-field {
  border-width: 1px;
  border-color: #b2bec3;
  min-height: 50px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .input-field:focus {
  box-shadow: none;
  border-color: #fc3345;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .input-field
  .ant-input {
  font-size: 14px;
  font-weight: 600;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input-content
  .ant-input-wrapper-focused:focus {
  box-shadow: none;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-explain-error {
  font-size: 13px;
}
.login_form_inp .ant-form-item {
  margin-bottom: 8px;
}
.login_form_fp.ant-form-item {
  margin-bottom: 20px;
}
.login_form_fp .ant-form-item-control .ant-form-item-control-input {
  min-height: auto;
}
.login_form_fp
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  a {
  font-size: 14px;
  font-weight: 500;
  color: #2f373b;
  font-family: "Poppins", sans-serif;
}
.login_form_fp
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  a:hover {
  color: #eb4a1c;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input-content
  .ant-btn-primary {
  min-height: 50px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  font-family: "Poppins", sans-serif;
  background-color: #fc3345;
}
.right_login_form
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input-content
  .ant-btn-primary:hover {
  background-color: #121212;
}
.hp-h-100 {
  height: 100% !important;
}
.login-container {
  position: relative;
  min-height: 100vh;
  margin: 0 !important;
  overflow: hidden;
}
.app-logo {
  height: 70px;
}
.login-background-image .hp-logo-item {
  position: absolute;
  top: 0;
  left: 0;
  margin: 64px;
}
.login-background-image .hp-h-100 {
  align-items: center;
  justify-content: center;
}
.right_login_form .hp-h-100 {
  align-items: center;
}
.right-login h3 {
  font-size: 42px;
  line-height: 48px;
  color: #2d3436;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.right-login form {
  margin-top: 34px;
  
}
.login-fr{
  margin-bottom: 20px;
}
.left_text h1 {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2f373b;
  font-family: "Poppins", sans-serif;
}
.left_text span {
  color: #333333;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 768px) and (max-width: 991px) {
  .right_login_form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input-content
    .ant-btn-primary {
    min-height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .login-background-image h1.ant-typography {
    font-size: 24px;
  }
  .login-background-image h1.ant-typography br {
    display: none;
  }
  .login-background-image span.ant-typography {
    font-size: 14px;
  }
  .login-background-image span.ant-typography br {
    display: none;
  }
  .login-background-image {
    height: 70vh;
  }
  .right_login_form {
    padding: 50px 0px;
  }
  .right_login_form
    .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input-content
    .ant-btn-primary {
    min-height: 50px;
  }
}
